// @flow
import * as SentrySDK from "@sentry/react";

const Sentry = {
  init(): void {
    if (process.env.NODE_ENV === "production") {
      SentrySDK.init({
        dsn: "https://43122d0e6e4c410c8e4348f8c34db5fc@o1018928.ingest.sentry.io/4505589279227904",
        integrations: [SentrySDK.browserTracingIntegration(), SentrySDK.replayIntegration()],
        tracesSampleRate: 0.2,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  },
  captureException(error: Error): void {
    if (process.env.NODE_ENV === "production") {
      SentrySDK.captureException(error);
    } else {
      console.error(error);
    }
  },
};

export default Sentry;
