// @flow
import * as React from "react";

import { QueryClient, QueryClientProvider } from "react-query";

import Main from "./Main";
import Header from "./Header";
import Footer from "./Footer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App(): React.Node {
  return (
    <QueryClientProvider client={queryClient}>
      <Header />
      <Main />
      <Footer />
    </QueryClientProvider>
  );
}

export default App;
