// @flow
import * as React from "react";

import { Link } from "react-router-dom";

import AuthService from "services/auth";

import Navbar from "./Navbar";

import "./index.scss";

function Header(): React.Node {
  const isAuthenticated = AuthService.isAuthenticated();

  React.useEffect(() => {
    const navbar = document.getElementById("navbar");
    const navLinks = document.getElementsByClassName("nav-link");

    const collapse = new window.bootstrap.Collapse(navbar, {
      toggle: false,
    });

    for (const element of navLinks) {
      element.addEventListener("click", () => collapse.hide());
    }

    return () => {
      for (const element of navLinks) {
        element.removeEventListener("click", () => collapse.hide());
      }
    };
  }, []);

  return (
    <header className="Header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            SmartLink by Vortex
          </Link>

          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbar">
            {isAuthenticated === true ? <Navbar.LoggedIn /> : <Navbar.LoggedOut />}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
