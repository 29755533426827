// @flow
import * as React from "react";

import { Button, Result } from "antd";

function ApplicationError(): React.Node {
  return (
    <Result
      status="error"
      title="Application error"
      subTitle="Sorry, an unexpected error occurred in the application. We recommend reloading the page."
      extra={
        <React.Fragment>
          <Button type="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
          <Button href="/">Back Home</Button>
        </React.Fragment>
      }
    />
  );
}

export default ApplicationError;
